import BrowserIconsLined from '../helpers/icons/BrowserIconsLined'
import {
    UserPlusIcon,
    HomeIcon,
    LightBulbIcon,
    BriefcaseIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import { onboardingCreatedDate } from '../helpers/constants/constants'
import {
    WizardStepType,
    WizardStepMultiChoice,
    WizardStepCustom,
} from '../components/wizard/types'
import { WizardController } from '../components/wizard/WizardController'
import BookmarksSection from '../share/BookmarksSection'
import React, { useEffect, useState } from 'react'
import { Organisation, User } from '../apiClient'
import {
    BookmarkNodeV2,
    bookmarks,
    getBookmarks,
    getBookmarksV2,
    shareNewFolder,
} from '../extensionCommunication/messager'
import Invite from '../helpers/commonComponents/Invite'
import {
    isBillingAction,
    PaymentPlans,
    PaymentPreviewDisplay,
} from '../helpers/commonComponents/PaymentPreviewDisplay'
import SyncConfirmationScreen from '../share/Wizard/SyncConfirmationScreen'
import apiHelper from '../apiClient/defaultApiClient'
import ShareConfirmationScreen from '../share/Wizard/ShareConfirmationScreen'
import { useExtensionState } from '../dashboard/Controller'
import BookmarkTree from '../share/BookmarkTree'
import { getBookmarksV2Version, isVersionLower } from '../helpers/versionChecks'
import Alert from '../helpers/customComponents/Alert'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import FreeTrialModal from './FreeTrialModal'
import { useLocation } from 'react-router-dom'
import {
    formatFreeTrialDate,
    handleOpenLiveChat,
} from '../helpers/helperFunctions'

interface WizardState {
    selectedBookmarkFolder?: string
    emailAddresses?: string[]
}

interface UserData {
    user: User
    organisation?: Organisation & {
        members: string[]
    }
}

export function ShareFolderWizard(props: {}) {
    const { extensionState } = useExtensionState()
    const [wizardState, setWizardState] = useState<WizardState>({
        selectedBookmarkFolder: undefined,
    })

    const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false)
    const [hasValidationError, setHasValidationError] = useState<boolean>(false)
    const [displayPlanSelector, setDisplayPlanSelector] =
        useState<boolean>(false)
    const location = useLocation()

    const [bookmarks, setBookmarks] = useState<null | BookmarkNodeV2[]>(null)
    const [oldBookmarks, setOldBookmarks] = useState<null | bookmarks[]>(null)
    const [userData, setUserData] = useState<UserData | null>(null)
    const userCreatedDate = userData?.user.created || new Date()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [displayFreeTrialModal, setDisplayFreeTrialModal] =
        useState<boolean>(false)

    const freeTrialExpiryDate =
        userData?.user.freeTrial && userData?.user.freeTrial

    const getUser = async () => {
        let response = await apiHelper.getProfile().catch(async (e) => {
            throw e
        })
        return response as User
    }

    function pruneTreeLayers(tree: BookmarkNodeV2[]): BookmarkNodeV2[] {
        // Helper function to prune children beyond the second layer
        function pruneChildrenAtDepth(
            nodes: BookmarkNodeV2[],
            depth: number
        ): BookmarkNodeV2[] {
            // Check if the current depth is where we want to prune
            if (depth > 2) {
                return [] // Beyond second layer, prune all
            }
            // Otherwise, iterate over the children and prune their children
            return nodes.map((node) => {
                return {
                    ...node,
                    children: pruneChildrenAtDepth(node.children, depth + 1),
                }
            })
        }

        // Begin pruning from the first layer (depth = 1)
        return pruneChildrenAtDepth(tree, 1)
    }

    const getOrganisationMembers = async (
        organisationId: number
    ): Promise<string[]> => {
        const members = await apiHelper.getOrganisationMembers({
            organisationId: organisationId,
        })
        return members.map((m) => m.email)
    }

    const getNonSharedBookmarks = async () => {
        if (!extensionState?.isInstalled) return
        const response = await getBookmarksV2()
        // Prune layers more than 2 deep
        const prunedTree = pruneTreeLayers(response)
        const filteredTree = prunedTree
            .filter((node) => !node.remoteData) // Keep nodes without remoteData at the first level
            .map((node) => ({
                ...node,
                children:
                    node.children?.filter((child) => !child.remoteData) || [], // Do the same for children
            }))
        return filteredTree
    }

    const getNonSharedBookmarksOld = async () => {
        if (!extensionState?.isInstalled) return
        const response = await getBookmarks()
        return response?.filter((bookmark) => !bookmark.isShared)
    }

    // We need to do this to display the name of the
    // bookmark folders in the confirmation screen because
    // we are now dealing with more than one layer of bookmarks in
    // a bookmark tree and we do not know which layer the bookmark is in
    function findNameById(
        tree: BookmarkNodeV2[] | null,
        selectedBookmarkFolderID: string | undefined
    ): string {
        if (!tree) {
            return ''
        }
        // Search in the top-level nodes
        for (const node of tree) {
            if (node.id === selectedBookmarkFolderID) {
                return node.name
            }
            // Search in the second-level nodes
            for (const child of node.children || []) {
                if (child.id === selectedBookmarkFolderID) {
                    return child.name
                }
            }
        }
        // If the ID wasn't found, return empty string
        return ''
    }

    // check if user has recently registered and display free trial modal
    useEffect(() => {
        const hasRecentlyRegistered = !!location?.state?.register
        if (hasRecentlyRegistered && userCreatedDate > onboardingCreatedDate) {
            setDisplayFreeTrialModal(true)
        }
    }, [])

    useEffect(() => {
        getUser()
            .then(async (u) => {
                let data: UserData = {
                    user: u,
                }
                // setUser(u)
                let organisationID: number | undefined =
                    data.user?.orgIds && data.user?.orgIds[0]
                        ? data.user.orgIds[0]
                        : undefined
                if (organisationID) {
                    const members = await getOrganisationMembers(organisationID)
                    const org = await apiHelper.getOrganisation({
                        organisationId: organisationID,
                    })
                    data.organisation = {
                        members: members,
                        ...org,
                    }
                }
                setUserData(data)
            })
            .then(() => setIsLoading(false))

        if (extensionState?.isInstalled) {
            if (isVersionLower(extensionState.version, getBookmarksV2Version)) {
                getNonSharedBookmarksOld().then((b) => setOldBookmarks(b ?? []))
            } else {
                getNonSharedBookmarks().then((b) => setBookmarks(b ?? []))
            }
        }
        apiHelper.getUserProfilePaymentMethods().then((methods) => {
            if (methods.length > 0) {
                setHasPaymentMethod(true)
            }
        })
    }, [])

    //This useEffect is responsible for getting bookmarks when extension state changes,
    //this typically happens when the user installs the extension when the wizard is already open
    useEffect(() => {
        if (extensionState?.isInstalled) {
            if (isVersionLower(extensionState.version, getBookmarksV2Version)) {
                getNonSharedBookmarksOld().then((b) => setOldBookmarks(b ?? []))
            } else {
                getNonSharedBookmarks().then((b) => setBookmarks(b ?? []))
            }
        }
    }, [extensionState])

    const hasExistingFreeTrial = userData?.user.freeTrial !== undefined
    let organisationID: number | undefined = userData?.organisation?.id

    const isEnterpriseFolder = !!userData?.organisation

    const requiresPayment = isBillingAction(
        userData?.user.email ?? '',
        wizardState.emailAddresses ?? [],
        isEnterpriseFolder ? userData?.organisation?.members ?? [] : [],
        isEnterpriseFolder
    )

    const offerFreeTrial =
        !hasPaymentMethod &&
        !organisationID &&
        !hasExistingFreeTrial &&
        requiresPayment &&
        userCreatedDate < onboardingCreatedDate

    const bookmarkSelector = () => {
        if (
            !extensionState?.isInstalled ||
            isVersionLower(extensionState.version, getBookmarksV2Version)
        ) {
            return (
                <BookmarksSection
                    extensionState={extensionState}
                    bookmarks={oldBookmarks ?? []}
                    isLoading={false}
                    formErrorsLocalFolderId={undefined}
                    onRefresh={() => {
                        getNonSharedBookmarksOld().then((b) =>
                            setOldBookmarks(b ?? [])
                        )
                    }}
                    handleBookmarkFolderSelect={(localFolderId) => {
                        setHasValidationError(false)
                        setWizardState({
                            ...wizardState,
                            selectedBookmarkFolder: localFolderId,
                        })
                    }}
                    localFolderId={wizardState.selectedBookmarkFolder ?? ''}
                    className="mt-2 sm:mt-6 w-full"
                />
            )
        }
        return (
            <BookmarkTree
                freeTrialExpiryDate={freeTrialExpiryDate}
                extensionState={extensionState}
                bookmarks={bookmarks}
                isLoading={false}
                formErrorsLocalFolderId={undefined}
                onRefresh={() => {
                    getNonSharedBookmarks().then((b) => setBookmarks(b ?? []))
                }}
                handleBookmarkFolderSelect={(localFolderId) => {
                    setHasValidationError(false)
                    setWizardState({
                        ...wizardState,
                        selectedBookmarkFolder: localFolderId,
                    })
                }}
                localFolderId={wizardState.selectedBookmarkFolder ?? ''}
                className="mt-2 sm:mt-6 w-full"
            />
        )
    }

    const inviteByEmail = () => {
        const hasSpecifiedEmailsToInvite =
            (wizardState.emailAddresses?.length ?? 0) > 0

        let displayPaymentPreview = false
        if (hasSpecifiedEmailsToInvite) {
            if (userData?.organisation) {
                displayPaymentPreview = !userData.organisation.hasForcedPlan
            } else {
                displayPaymentPreview =
                    hasSpecifiedEmailsToInvite &&
                    hasPaymentMethod &&
                    !hasExistingFreeTrial
            }
        }

        return (
            <div className={'mt-16 w-full'}>
                {freeTrialExpiryDate && (
                    <Alert
                        className="mb-8"
                        alertType={AlertType.success}
                        alertText={
                            <p>
                                Free trial enabled until{' '}
                                {freeTrialExpiryDate && (
                                    <strong>
                                        {formatFreeTrialDate(
                                            freeTrialExpiryDate
                                        )}
                                    </strong>
                                )}
                                ; visit our{' '}
                                <a
                                    className="cursor-pointer font-bold underline hover:no-underline"
                                    target="_blank"
                                    href="https://bookmarkllama.com/pricing"
                                >
                                    pricing page
                                </a>{' '}
                                for more information. Get in touch on{' '}
                                <button
                                    onClick={handleOpenLiveChat}
                                    className="w-fit cursor-pointer font-bold underline hover:no-underline"
                                >
                                    our live chat
                                </button>{' '}
                                to upgrade.
                            </p>
                        }
                        showAlert
                    />
                )}
                <Invite
                    isLoading={false}
                    handleInviteOnChange={(_emails) => {
                        setHasValidationError(false)
                        setWizardState({
                            ...wizardState,
                            emailAddresses: _emails,
                        })
                    }}
                    emailAddresses={wizardState.emailAddresses}
                />

                {displayPaymentPreview && (
                    <PaymentPreviewDisplay
                        currentOrganisationUserEmails={
                            userData?.organisation?.members ?? []
                        }
                        currentUser={userData?.user.email ?? ''}
                        currentFolderUserEmails={[]}
                        userEmailsToAdd={wizardState.emailAddresses ?? []}
                        organisationId={organisationID}
                    />
                )}
            </div>
        )
    }

    const syncPath: WizardStepType = {
        id: 'bookmarkSelect',
        type: 'custom',
        title: 'Which bookmark folder would you like to sync?',
        component: bookmarkSelector(),
        customNextButton: (nextStep, validate) => {
            const [loading, setLoading] = useState<boolean>(false)
            return (
                <Button
                    buttonText={'Sync'}
                    disabled={loading}
                    buttonTheme={ButtonTheme.primary}
                    className="px-8 sm:w-fit sm:px-20"
                    onClick={async () => {
                        const isValid = validate && (await validate())
                        setHasValidationError(!isValid)
                        if (!isValid) return

                        setLoading(true)
                        if (!wizardState.selectedBookmarkFolder) return
                        await shareNewFolder(
                            wizardState.selectedBookmarkFolder,
                            null,
                            undefined,
                            undefined
                        ).then(() => nextStep())
                    }}
                />
            )
        },
        nextStep: {
            id: 'confirmationPage',
            type: 'end',
            finishPage: (
                <SyncConfirmationScreen
                    user={userData?.user as User}
                    localFolderID={wizardState.selectedBookmarkFolder ?? ''}
                    folderName={findNameById(
                        bookmarks,
                        wizardState.selectedBookmarkFolder
                    )}
                />
            ),
        },
        errorMessage: 'Please select a bookmark folder',
        validate: async () => {
            return wizardState.selectedBookmarkFolder !== undefined
        },
    }

    const sharePath: WizardStepType = {
        type: 'custom',
        title: 'Which bookmark folder would you like to share?',
        id: 'bookmarkSelectShare',
        component: bookmarkSelector(),
        errorMessage: 'Please select a bookmark folder',
        validate: async () => {
            return wizardState.selectedBookmarkFolder !== undefined
        },
        nextStep: {
            type: 'custom',
            id: 'emailInvite',
            title: 'Who would you like to share the folder with?',
            validate: async () => {
                return !(
                    wizardState.emailAddresses === undefined ||
                    wizardState.emailAddresses.length === 0
                )
            },
            errorMessage:
                'Please input the email addresses of the users you wish to share with',
            component: inviteByEmail(),
            customNextButton:
                // offerFreeTrial
                //     ? () => {
                //           return (
                //               <Button
                //                   buttonText={'Select Plan'}
                //                   buttonTheme={ButtonTheme.primary}
                //                   className="px-8 sm:w-fit sm:px-20"
                //                   onClick={async () => {
                //                       setDisplayPlanSelector(true)
                //                   }}
                //               />
                //           )
                //       }
                //     :
                (nextStep, validate) => {
                    const [loading, setLoading] = useState<boolean>(false)
                    return (
                        <Button
                            buttonText={'Share'}
                            disabled={loading}
                            buttonTheme={ButtonTheme.primary}
                            className="px-8 sm:w-fit sm:px-20"
                            onClick={async () => {
                                const isValid = validate && (await validate())
                                setHasValidationError(!isValid)
                                if (!isValid) return

                                setLoading(true)
                                await shareNewFolder(
                                    wizardState.selectedBookmarkFolder ?? '',
                                    null,
                                    wizardState.emailAddresses,
                                    organisationID
                                ).then(() => nextStep())
                            }}
                        />
                    )
                },
            nextStep: {
                id: 'confirmationPage',
                type: 'end',
                finishPage: (
                    <ShareConfirmationScreen
                        invitedEmails={wizardState.emailAddresses ?? []}
                        localFolderID={wizardState.selectedBookmarkFolder ?? ''}
                        folderName={findNameById(
                            bookmarks,
                            wizardState.selectedBookmarkFolder
                        )}
                    />
                ),
            },
        },
    }

    const wizardSteps: WizardStepMultiChoice = {
        id: 'syncOrShare',
        title: 'Sync or Share?',
        type: 'multi-choice',
        errorMessage: '',
        options: [
            {
                title: 'Share bookmarks with your team',
                id: 'shareFolderTeam',
                icon: <UserPlusIcon className="stroke-1" />,
                tooltip:
                    'Turn your bookmarks into a shared resource. With this option, you can collaborate on bookmarks with your team.',
                nextStep: sharePath,
            },
            {
                title: 'Sync between work and personal devices',
                id: 'syncDevices',
                icon: <LightBulbIcon className="stroke-1" />,
                tooltip:
                    'Selectively sync bookmark folders across all your browsers and between your work and personal devices.',
                nextStep: syncPath,
            },
            {
                title: 'Sync across devices and browsers',
                id: 'syncDevicesAndBrowsers',
                icon: <BrowserIconsLined />,
                tooltip:
                    'Selectively sync bookmark folders across all your browsers and devices.',
                nextStep: syncPath,
            },

            {
                title: 'Share with friends and family',
                id: 'shareFolderFamily',
                icon: <HomeIcon className="stroke-1" />,
                tooltip:
                    'Turn your bookmarks into a shared resource. With this option, you can collaborate on bookmarks with friends and family.',
                nextStep: sharePath,
            },
        ],
    }

    const e2ePath: WizardStepType = {
        type: 'custom',
        title: 'Which bookmark folder would you like to share?',
        id: 'bookmarkSelectShare',
        component: bookmarkSelector(),
        nextStep: {
            type: 'multi-choice',
            id: 'shareWith',
            title: 'Share with someone else?',
            errorMessage: '',
            options: [
                {
                    title: 'Yes',
                    id: 'yes',
                    icon: <UserGroupIcon className="stroke-1" />,
                    nextStep: {
                        type: 'custom',
                        id: 'emailInvite',
                        title: 'Who would you like to share the folder with?',
                        validate: async () => {
                            return !(
                                wizardState.emailAddresses === undefined ||
                                wizardState.emailAddresses.length === 0
                            )
                        },
                        errorMessage:
                            'Please input the email addresses of the users you wish to share with',
                        component: inviteByEmail(),
                        customNextButton: (nextStep, validate) => {
                            const [loading, setLoading] =
                                useState<boolean>(false)
                            return (
                                <Button
                                    buttonText={'Share'}
                                    disabled={loading}
                                    buttonTheme={ButtonTheme.primary}
                                    className="px-8 sm:w-fit sm:px-20"
                                    onClick={async () => {
                                        const isValid =
                                            validate && (await validate())
                                        setHasValidationError(!isValid)
                                        if (!isValid) return

                                        setLoading(true)
                                        await shareNewFolder(
                                            wizardState.selectedBookmarkFolder ??
                                                '',
                                            null,
                                            wizardState.emailAddresses,
                                            organisationID
                                        ).then(() => nextStep())
                                    }}
                                />
                            )
                        },
                        nextStep: {
                            id: 'confirmationPage',
                            type: 'end',
                            finishPage: (
                                <ShareConfirmationScreen
                                    invitedEmails={
                                        wizardState.emailAddresses ?? []
                                    }
                                    localFolderID={
                                        wizardState.selectedBookmarkFolder ?? ''
                                    }
                                    folderName={findNameById(
                                        bookmarks,
                                        wizardState.selectedBookmarkFolder
                                    )}
                                />
                            ),
                        },
                    },
                },
                {
                    title: 'No',
                    id: 'no',
                    icon: <BriefcaseIcon className="stroke-1" />,
                    nextStep: {
                        type: 'custom',
                        id: 'finish-sync',
                        title: '',
                        validate: async () => {
                            return true
                        },
                        errorMessage: '',
                        component: <div />,
                        customNextButton: (nextStep, validate) => {
                            const [loading, setLoading] =
                                useState<boolean>(false)
                            return (
                                <Button
                                    buttonText={'Sync'}
                                    disabled={loading}
                                    buttonTheme={ButtonTheme.primary}
                                    className="px-8 sm:w-fit sm:px-20"
                                    onClick={async () => {
                                        const isValid =
                                            validate && (await validate())
                                        setHasValidationError(!isValid)
                                        if (!isValid) return

                                        setLoading(true)
                                        if (!wizardState.selectedBookmarkFolder)
                                            return
                                        await shareNewFolder(
                                            wizardState.selectedBookmarkFolder,
                                            null,
                                            undefined,
                                            undefined
                                        ).then(() => nextStep())
                                    }}
                                />
                            )
                        },
                        nextStep: {
                            id: 'confirmationPage',
                            type: 'end',
                            finishPage: (
                                <SyncConfirmationScreen
                                    user={userData?.user as User}
                                    localFolderID={
                                        wizardState.selectedBookmarkFolder ?? ''
                                    }
                                    folderName={findNameById(
                                        bookmarks,
                                        wizardState.selectedBookmarkFolder
                                    )}
                                />
                            ),
                        },
                    },
                },
            ],
        },
    }

    const getWizardPath = () => {
        // check if they don't have org, free trial, weren't invited and created after onboarding flow

        if (import.meta.env.MODE === 'e2e') {
            return e2ePath
        } else if (userCreatedDate > onboardingCreatedDate) {
            return sharePath
        } else return wizardSteps
    }

    // const handleOpenLiveChat = () => {
    //     if (window.$chatwoot) {
    //         window.$chatwoot.toggle('open') // Toggles Chatwoot widget
    //     } else {
    //         console.error('Chatwoot is not initialized.')
    //     }
    // }

    return isLoading ? (
        <div className="w-full flex justify-center items-center h-[75vh]">
            <SpinnerSvgIcon height="40" width="40" />
        </div>
    ) : (
        <>
            {/* {displayPlanSelector && (
                <PaymentPlans
                    onExit={() => setDisplayPlanSelector(false)}
                    onPlanSelect={async () => {
                        await apiHelper.postFreeTrial()
                        //refresh user object, to get freeTrial state
                        const user = await getUser()
                        setUserData({
                            user: user,
                        })
                        setDisplayPlanSelector(false)
                    }}
                    userId={userData?.user.id}
                />
            )} */}
            <WizardController
                wizardSteps={getWizardPath()}
                hasValidationError={hasValidationError}
                setHasValidationError={(hasError) => {
                    setHasValidationError(hasError)
                }}
                userCreated={userData?.user.created}
            />

            <FreeTrialModal
                open={displayFreeTrialModal}
                setOpen={setDisplayFreeTrialModal}
                modalTitle={"You've started a free trial!"}
                children={
                    <div className="flex flex-col">
                        <p className="text-lg mb-2">
                            Your free trial has stared and will end on{' '}
                            <strong>
                                {freeTrialExpiryDate &&
                                    `${formatFreeTrialDate(
                                        freeTrialExpiryDate
                                    )}`}
                            </strong>
                            , until then you have access to all of Bookmark
                            Llama's features.
                        </p>
                        <p className="text-lg">
                            To continue using Bookmark Llama after your trial
                            ends, simply let us know which{' '}
                            <a
                                className="text-primary font-bold underline hover:cursor-pointer"
                                href="https://www.bookmarkllama.com/pricing"
                                target="_blank"
                            >
                                paid plan
                            </a>{' '}
                            you'd like via{' '}
                            <button
                                onClick={handleOpenLiveChat}
                                className="w-fit text-center text-lg font-bold rounded text-primary underline"
                            >
                                our live chat
                            </button>
                            .
                        </p>
                    </div>
                }
                primaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Continue"
                        disabled={isLoading}
                        className="mt-4 w-full"
                        buttonTheme={ButtonTheme.primary}
                        onClick={() => {
                            setDisplayFreeTrialModal(!displayFreeTrialModal)
                        }}
                    />
                }
            />
        </>
    )
}
