export const UNAUTHORIZED_ERROR = 401
export const NOT_FOUND_ERROR = 404

export const MAIL_TO_EMAIL = 'support@bookmarkllama.com'

export const CONTACT_PAGE = 'https://www.bookmarkllama.com/contact'

export const FREE_FOLDER_LIMIT = 2
export const ONE_USER_IN_FOLDER = 1
export const ONE_USER_IN_ORGANISATION = 1

export const ONE_USER_TO_ADD = 1
export const ZERO_USER_TO_ADD = 0
export const CREATOR = 1

export const PREMIUM_PRICE = 5
export const ORGANISATION_PRICE = 7

export const CHROME_EXTENSION_URL =
    'https://chrome.google.com/webstore/detail/bookmark-llama/mimpfplfhbocogjgjoiklgefbfkcflph'

export const FIREFOX_EXTENSION_URL =
    'https://addons.mozilla.org/en-GB/firefox/addon/bookmark-llama/'

export const EDGE_EXTENSION_URL =
    'https://microsoftedge.microsoft.com/addons/detail/bookmark-llama/ipnohflnkaljegnpiepbfefnnodmgkmp'

const alisondamonte = [6847, 6846, 6849, 6848]
const intraspire = [6850, 6851, 6852]
const theseedLighting = [6880, 6881, 6883]

const dattenburger = [6997, 4453, 6695]

const einwert = [4598, 4599]

//Paid plan ended 12/11/2024
const credilife = [4636, 4631, 4630, 4633, 4634, 4683, 4635, 5394, 5395]
const weCodeSwiss = [5731, 5732, 5728, 5729, 5730, 5733, 5734]
export const userIdsHandSelectedFreeTrialExpiry: number[] = [
    ...alisondamonte,
    ...intraspire,
    ...theseedLighting,
    ...dattenburger,
    ...credilife,
    ...weCodeSwiss,
]

export const userIdsHandSelectedAccountSharing: number[] = [
    3894,
    3166,
    3527,
    953,
    3712,
    947,
    2690,
    2441,
    5736,
    6037,
    6070,
    ...einwert,
]

export const onboardingCreatedDate = new Date('2024-11-06T04:57:36Z')
